export enum EUpdate {
    REGISTER = 'REGISTER',
    PLACE_DOB = "PLACE_DOB",
    READYTOMARRY = "READYTOMARRY",
    SELECT = "SELECT",
}

export interface IUserData {
    fathersName: string | null | undefined;
    familyAnnualIncome?:string | null;
    expectation?: string | null;
    occupationCity?: string | null;
    fileName?:string |null;
    relationWithGuardian?:string | null;
    agriculturalLand?:string | null;
    newProfile?: string | null;
      custId?:string;
      firstName?: string | null;
      lastName?: string | null;
      gender?: string | null;
      emailId?:string | null;
      paymentId?:string |null;
      dateOfBirth?: string | null;
      birthTime?: string | null;
      middleName?:string | null;
      birthPlace?: string | null;
      maritalStatus?: string | null;
      motherTongue?: string | null;
      height?: string | null;
      weight?: string | null;
      colourOfBody?: string | null;
      physicalStatus?: string | null;
      mobileNo?: string | null;
      spec?: boolean;
      hobby?:string;
      addr?: string;
      locality?:string;
      pincode?:string;
      educationDetails?: string | null;
      employedIn?: string | null;
      occupation?: string | null;
      organization?: string | null;
      annualIncome?: string | null;
      bloodGroup?: string;
      ownHouse?: string | null;
      caste?:string |null;
      subcaste?:string | null;
      guardiansFullname?: string | null;
      relWithGuardian?: string | null ;
      readyToMarryWithinSubcast?: string | null,
      occOfGuardian?: string | null;
      UncleName?: string | null;
      hobbies?: string | null ;
      totalFamily?: string | null;
      File?:File|null ;
      fathersMobileNo?:string | null;
      noBrothers?: string | null;
      noSisters?: string | null;
      noMarreiedBrothers?: string | null;
      noMarreiedSisters?: string | null;
      primaryMobNumber?: string | null;
      familyDetails?: IFamilyData;
      status?: string;
      transactionId?:string,
      amount?:string
      uri?:string,
        name?: string,
        type?: string,
  }

  export interface IFamilyData {
    family_Id?: number;
    fathersName?: string;
    familyAnnualIncome?:string|null;
    mothersName?: string;
    fathersOccupation?: string;
    mothersOccupation?: string;
    familyMembers?: number;
    fathersMobileNo?:string | null;
    numberOfBrothers?: number;
    numberOfSisters?: number;
    brothersMarried?: number;
    sistersMarried?: number;
    houseOwnership?: string;
    maternalUnclesName?: string;
}

export interface IUpdateApiResponse {
    data: IUserData;
    status: string;
    message: string;
    responseCode: number;
}

export interface IUpdateState {
    registerLoading: boolean;
    registerSuccess: IUpdateApiResponse | null;
    registerError: string | null;
}