import React from 'react';
import './App.css';
import { Link, Route, Routes } from "react-router-dom";
import DashBoard from './Application/screens/Dashboard/DashBoard.component';
import Layout from './components/Layout/Layout.component';
import Navbar from './components/Navbar/Navbar.components';
import Footer from './components/Footer/Footer.component';
import Services from './Application/screens/Services/Services.components';
import AboutUs from './Application/Aboutus/AboutUs.component';
import ContactUs from './Application/ContactUs/ContactUs.component';
import Gallery from './Application/Gallery/Gallery.components';
import LogIn from './Application/LogIn/LogIn';
import TermsAndCondition from './Application/TermsAndPrivacy/TermsAndCondition/TermsAndCondition';
import PrivacyPolicy from './Application/TermsAndPrivacy/PrivacyPolicy/PrivacyPolicy';
import { EColor } from './styles/Colors/Colors';
import { ToastContainer } from 'react-toastify';
import LogInUser from './Application/screens/LoginUser/LoginUser.component';
import QRCodePage from './Application/screens/QrScanner/QRCodePage.componet';
import CustomerTable from './Application/screens/customer/CustomerTable';
import Register from './Application/screens/RegisterScreen/Register';
import Splash from '../src/assets/Images/Splash.jpeg'
import SampleForm from './Application/sampleForm/SampleForm';
import CustomerDetails from './Application/CustomerDetails/CustomerDetails.component'

function App() {
  return (
    <div className="background-image">
      {/* <div style={{display:"flex",flexDirection:"row",alignItems:"center",justifyContent:"center"}}>
      <img src={Splash} className="logo"  alt="Splash" />
      <Link to="/" className="navbar-brand">
  <p className="travo" style={{ color: EColor.BLACK, fontSize: 24, marginTop: 20, textDecoration: 'underline' }}>
    Jain Utkarsh Mandal
  </p>
</Link>

      </div> */}
     <Navbar/>
     <div className="main-content">
        <Routes>
          <Route  path='/' element={<DashBoard/>}/>
          <Route  path='/Services' element={<Services/>}/>
          <Route  path='/AboutUs' element={<AboutUs/>}/>
          <Route  path='/ContactUs' element={<ContactUs/>}/>
          <Route  path='/Gallery' element={<Gallery/>}/>
          <Route  path='/LogIn' element={<LogIn/>}/>
          <Route  path='/TermsConditions' element={<TermsAndCondition/>}/>
          <Route  path='/PrivacyPolicy' element={<PrivacyPolicy/>}/>
          <Route  path='/j1/jainmatrimonyhealthMetric' element={<DashBoard/>}/>
          <Route  path='/QRCodePage' element={<QRCodePage/>}/>
          <Route  path='/LogInUser' element={<LogInUser/>}/>
          <Route  path='/CustomerTable' element={<CustomerTable/>}/>
          <Route  path='/Register' element={<Register/>}/>
          <Route  path='/SampleForm' element={<SampleForm data={undefined} isAdmin={undefined} handelPrevivewPage={undefined}/>}/>
          <Route path="/customer/:mobileNumber" element={<CustomerDetails />} />
        </Routes>
        <ToastContainer />
      </div>
     <Footer/>
    </div>
  );
}

export default App;
