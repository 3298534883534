import React from "react";
import Carasoul from "../../../components/Carasoul/Carasoul.componets";
import carouselData from "../../../Mockdata/CarasoulData";
import { EColor } from "../../../styles/Colors/Colors";
import Scanner from '../../../assets/Images/Scanner.jpeg'



import './DashBoard.css'
import ImageCarousel from "../../../components/GalleryCarasoul/ImageCarousel.component";

const DashBoard  = ()=>{
  return(
    <div>
      <div style={{display:"flex",justifyContent:"center"}}>
      <div style={{width:'100%',marginTop:10,overflow:'hidden',padding:16,alignSelf:"center",display:"flex",justifyContent:"center"}}>
         <ImageCarousel/>
      </div>
      </div>
      {/* <h1 style={{color:EColor.BROWN,textAlign:"center",marginTop:12,backgroundColor:'#e08585',width:"90%",flexDirection:"row",justifyContent:"center"}}>---------------------------------------- Jain Utkarsh Mandal welcomes You ----------------------------------------  </h1> */}
    <div  className="cardsMain">
        <div className="Cardscomp">
          {carouselData.map((data, index) => (
            <div className="subcomp">
            <Carasoul data={data} index={index} />
            </div>
          ))}
        </div>
        <div className="download">
        <div className="text-container" data-aos="zoom-in" >
          <h2 style={{ color: EColor.BROWN, textAlign: "center" }}>Join Jain Utkarsh Mandal  and Transform Your Matrimonial Journey!
</h2>
          <p className="texts">
          Discover your ideal life partner with Jain Utkarsh Mandal ! Whether you're seeking a meaningful connection or exploring potential matches,  connects you with individuals who share your values and aspirations. Enjoy a personalized, respectful, and enriching matchmaking experience. Start your journey to a fulfilling relationship today and be part of a community dedicated to genuine connections and shared traditions! <strong style={{color:EColor.BROWN,textDecorationLine:'underline'}}>Jain Utkarsh Mandal </strong> aims to revolutionize the matrimony experience for the global Jain community. We envision a future where our platform is the gold standard for Jain matchmaking, harnessing technology, tradition, and our unparalleled understanding of Jain values to:
          </p>
        </div>
        <div data-aos="zoom-in">
          <img src={Scanner} className="img" alt="About Us" />
        </div>
        </div>
         </div>
    </div>
  )
}

export default DashBoard;