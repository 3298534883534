import React, { useEffect, useState } from "react";
import "./CustomerTableStyle.css";
import { Link, useNavigate } from "react-router-dom";
import arrowBack from "../../../assets/Images/arrow_back.png";
import { EColor } from "../../../styles/Colors/Colors";
import { useAppDispatch, useAppSelector } from "../../../states/store";
import { getCardsData } from "../../../states/Slice/GetCards/getCards.slice";
import { Data } from "../../../states/Slice/GetCards/getCards.models";
import moment from "moment";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import { APIKEYS } from "../../../utils/Keys";
import { isPromise } from "util/types";
import { Modal, Button } from "react-bootstrap";

const CustomerTable = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [page, setPage] = useState(0); // Internal page state
  const [totalPages, setTotalPages] = useState(10);
  const [data, setData] = useState<Data>();
  const [hasNextPage, setHasNextPage] = useState(true);
  const [selectedCustId, setSelectedCustId] = useState<null | BigInteger>(null);
  const [showModal, setShowModal] = useState(false);

  const reducer = useAppSelector((state) => state.getCardsSlice);

  // Fetch token for both view and download functions
  const fetchToken = async () => {
    const tokenData = {
      username: "jainmatrimonyapi",
      password: "jainmatrimonyapi",
    };
    const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
    return tokenResponse.data.token;
  };

  const viewPDF = async () => {
    try {
      const token = await fetchToken();

      const response = await fetch(`${APIKEYS.API}customers/pdf`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Open the PDF in a new tab for viewing
      window.open(url, "_blank");
    } catch (error) {
      console.error("Error viewing PDF:", error);
    }
  };

  const viewDetails = async (mobileNum: string) => {
    try {
      const token = await fetchToken(); // Ensure you're authenticated
      //console.log(token)
      // Make a GET request to the API
      const response = await axios.get(
        `${APIKEYS.API}customerbymobile/${mobileNum}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      // Handle the response
      const customerDetails = response.data;
      //console.log(customerDetails)
      //console.log(customerDetails.data)
      // Display customer details (for simplicity, using an alert)
      alert(
        `Customer Details:\nName: ${customerDetails.data.firstName} ${customerDetails.data.lastName}\nEmail: ${customerDetails.emailId}\nRegistration Date: ${customerDetails.createdOn}`
      );
    } catch (error) {
      console.error("Error fetching customer details:", error);
      alert("Failed to fetch customer details. Please try again.");
    }
  };
  const deleteCustomerById = async (custId: BigInteger) => {
    try {
      const token = await fetchToken();
      //console.log('token',token);

      const response = await axios.delete(`${APIKEYS.API}customer/${custId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      //console.log('response::',response);
      if (response.data.message == "Customer Deleted Successfully") {
        toast.success("Candidate Deleted SuccessFully", {
          position: "top-right",
        });
        window.location.reload();
      } else {
        toast.error("Deletion Operation Failed, Please try after some time", {
          position: "top-right",
        });
      }
    } catch (error) {
      console.error("Customer Deleted Failed:", error);
    }
  };

  const handleDeleteClick = (custId: BigInteger) => {
    setSelectedCustId(custId);
    setShowModal(true); // Show the confirmation modal
  };

  const handleConfirmDelete = () => {
    if (selectedCustId) {
      deleteCustomerById(selectedCustId);
    }
    setShowModal(false); // Close the modal
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedCustId(null); // Reset selected customer ID
  };

  const downloadPDF = async () => {
    try {
      const token = await fetchToken();

      const response = await fetch(`${APIKEYS.API}customers/pdf`, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/pdf",
        },
      });

      if (!response.ok) {
        throw new Error("Failed to fetch PDF");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Create a temporary link element for downloading the PDF
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "registration_list.pdf"); // Set the file name
      document.body.appendChild(link);

      // Simulate a click to automatically download the PDF
      link.click();

      // Cleanup: remove the link and revoke the object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading PDF:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const d = { page: page };
      await dispatch(getCardsData(d.page.toString()));
    };
    fetchData();
  }, [dispatch, page]);

  useEffect(() => {
    if (reducer.CardSuccess?.data) {
      setData(reducer.CardSuccess.data);
      setTotalPages(reducer.CardSuccess.data.totalPages);

      // Check if there is data for the next page
      setHasNextPage(
        reducer.CardSuccess.data.content.length > 0 || page < totalPages - 1
      );
    }
  }, [reducer, page, totalPages]);

  useEffect(() => {
    const userId = localStorage.getItem("userKey");
    if (userId !== "asWqFreafdgADFGRE@#%34563") {
      navigate("/LogIn");
    }

    // Handle browser back navigation
    const handlePopState = () => {
      localStorage.removeItem("userKey");
      navigate("/LogIn");
    };

    window.addEventListener("popstate", handlePopState);

    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, [navigate]);

  const handlePageChange = (newPage: number) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setPage(newPage - 1);
    }
  };

  const navigateToCustomerDetail = (primaryMobNumber: Number) => {
    navigate(`/customer/${primaryMobNumber}`);
  };

  const isProfilePic = (customer: any) => {
    if (customer.profilePic?.fileName) {
      return {
        borderRadius: 12,
        color: "#00ff00",
      };
    }
  };

  const isPaymentDone = (status: String) => {
    if (!status) {
      return {
        borderRadius: 12,
        color: "#ff1a1a",
      };
    }
  };

  const LogOut = () => {
    localStorage.removeItem("userKey");
    navigate("/LogInUser");
  };

  return (
    <div className="customerTable">
      <button
        onClick={(): void => navigate("/Register")}
        style={{
          backgroundColor: EColor.BROWN,
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
          paddingTop: 12,
          color: "white",
          borderRadius: 8,
          borderColor: "white",
          overflow: "hidden",
        }}
      >
        <p style={{ color: "white", fontSize: 12 }}>
          Registration form for admin
        </p>
      </button>

      <div className="customerTableTitle">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Link to="/AdminPanel" className="backArrow">
            <img className="arrow" src={arrowBack} alt="Back" />
          </Link>
        </div>
        <h2>Register List</h2>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            columnGap: 12,
          }}
        >
          <div className="customerCountCardsTotal">
            <div className="customerType" style={{ color: EColor.BROWN }}>
              Total Customers
            </div>
            <div className="customerCount" style={{ color: EColor.BROWN }}>
              {reducer.CardSuccess?.data.totalElements}
            </div>
          </div>

          {/* View PDF Button */}
          <button onClick={viewPDF} className="pay-button">
            View Registration List
          </button>

          {/* Download PDF Button */}
          <button onClick={downloadPDF} className="pay-button">
            Download Registration List
          </button>

          <div
            className="logOutButton"
            onClick={LogOut}
            style={{
              color: EColor.WHITE,
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              style={{
                width: 100,
                backgroundColor: "#ff3333",
                textAlign: "center",
                padding: 6,
                borderRadius: 8,
                borderColor: "red",
                color: "wheat",
              }}
            >
              Log Out
            </button>
          </div>
        </div>
      </div>

      {/* Table and Pagination logic */}
      <div className="customerDataTable">
        {/* Table code remains the same */}
        <table>
          <thead style={{ backgroundColor: EColor.WHITE, color: EColor.BROWN }}>
            <tr>
              <th>SrNo</th>
              <th>Reg. Number</th>
              <th>Reg. Date</th>
              <th>Name</th>
              <th>Mobile No.</th>
              <th>Payment Status</th>
              <th>Details</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {data?.content ? (
              data?.content?.map((customer: any, index: number) => (
                <tr key={customer.id} style={{ color: "black" }}>
                  <td>{index + 1}</td>
                  <td style={isProfilePic(customer)}>
                    {customer.registrationNo}
                  </td>
                  <td>{moment(customer.createdOn).format("DD/MM/YYYY")}</td>
                  <td>
                    {customer.firstName} {customer?.lastName}
                  </td>
                  <td>{customer.primaryMobNumber}</td>
                  <td style={isPaymentDone(customer.payment[0]?.status)}>
                    {customer.payment[0]?.status ? "Paid 1200/-" : " Not Paid"}
                  </td>
                  <td>
                    <button
                      onClick={() =>
                        navigateToCustomerDetail(customer.primaryMobNumber)
                      }
                      className="customerDataTableDoneButton"
                    >
                      View
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => handleDeleteClick(customer.custId)}
                      className="customerDataTableDeleteButton"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))
            ) : (
              <h2>No data Found</h2>
            )}
          </tbody>
        </table>
      </div>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Delete Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete this customer? This action cannot be
          undone.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Confirm
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Pagination buttons */}
      <div className="pagination">
        <button
          className="pagination-btn"
          onClick={() => handlePageChange(page)}
          disabled={page === 0}
        >
          Previous
        </button>

        <div className="pagination-numbers">
          {Array.from({ length: totalPages }, (_, i) => i + 1).map(
            (pageNumber) => (
              <button
                key={pageNumber}
                className={`pagination-number ${
                  pageNumber === page + 1 ? "active" : ""
                }`}
                onClick={() => handlePageChange(pageNumber)}
              >
                {pageNumber}
              </button>
            )
          )}
        </div>

        <button
          className="pagination-btn"
          onClick={() => handlePageChange(page + 2)}
          disabled={!hasNextPage}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default CustomerTable;
