import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomerApiResponse } from './customerDetails.models';
import axios from 'axios';
import { APIKEYS } from '../../../utils/Keys';

interface ICardState {
    CardSuccess: CustomerApiResponse | null;
    CardLoading: boolean;
    CardError: string | null;
}

const initialState: ICardState = {
    CardSuccess: null,
    CardLoading: false,
    CardError: null,
};


export const fetchCustomerDetails = createAsyncThunk(
    'customerDetails/fetchCustomerDetails',
    async (mobileNumber: string) => {
        try {
            // Step 1: Authenticate and get token
            const authData = {
                username: "jainmatrimonyapi",
                password: "jainmatrimonyapi",
            };
            const tokenResponse = await axios.post(`${APIKEYS.API}verify`, authData);
            const token = tokenResponse.data.token;

            if (token) {
                const headers = {
                    Authorization: `Bearer ${token}`,
                    'Content-Type': 'application/json',
                };

                const apiUrl = `${APIKEYS.API}customerbymobile/${mobileNumber}`;
                //console.log('Fetching Customer Details from:', apiUrl);

                const response = await axios.get<CustomerApiResponse>(apiUrl, { headers });

                if (response) {
                    //console.log('Customer Details Response:', response.data);
                    return response.data;
                }
            }
        } catch (error: any) {
            console.error('Error fetching customer details:', error);
            throw error; 
        }
    }
);

const customerDetailsSlice = createSlice({
    name: 'customerDetails',
    initialState,
    reducers: {
        resetData(state) {
            state.CardSuccess = null;
            state.CardLoading = false;
            state.CardError = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerDetails.pending, (state) => {
                state.CardLoading = true;
            })
            .addCase(fetchCustomerDetails.fulfilled, (state, action) => {
                state.CardLoading = false;
                if (action.payload) {
                    state.CardSuccess = action.payload;
                }
                state.CardError = null;
            })
           .addCase(fetchCustomerDetails.rejected, (state) =>{
            state.CardError = 'something went wrong';
            state.CardLoading = false;
           });
    },
});

export const { resetData } = customerDetailsSlice.actions;
export default customerDetailsSlice.reducer;
