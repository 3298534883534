import React, { useEffect, useState } from 'react';
import './ReadOnlyForm.css'; 
import { EColor } from '../../styles/Colors/Colors';
import { useAppDispatch } from '../../states/store';
import { postCustomerRegistration } from '../../states/Slice/registerUser/register.slice';
import { toast,ToastContainer } from 'react-toastify';
import { Modal, Button } from 'react-bootstrap'
import { useNavigate, useLocation } from 'react-router-dom';
import { initiatePhonePay } from '../../states/Slice/phonePay/Phonepay.slice';
import { postAdminCustomerRegistration } from '../../states/Slice/admInRegister/adminRegister.slice';
import spalsh from '../../assets/Images/Rectangle 2.png'


const SampleForm = ({ data,isAdmin,handelPrevivewPage }) => {

    const location = useLocation();
const navigation = useNavigate()
const [showModal, setShowModal] = useState(false);
const [datas, setDatas] = useState(null);

  const handleSubmit = () => {
    setShowModal(true); 
  };

  console.log('fff',isAdmin,data)
  

const dispatch = useAppDispatch()

const onsubmit = () => {
    if(isAdmin){
        dispatch(postAdminCustomerRegistration(data)).then((res)=>{
            if(res.payload){
              toast.success('Registered Successfully in Jain Utkarsh Mandal ', {
                position:'top-right'
              });
              navigation("/CustomerTable")
            }
           })
    }else{
        const payLoad = {
            number: data.primaryMobNumber,
            amount: "1"
          };
      
          const file = data.File; 
          const convertToBase64 = (file) => {
            return new Promise((resolve, reject) => {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = () => resolve(reader.result);
              reader.onerror = (error) => reject(error);
            });
          };
        
          dispatch(initiatePhonePay(payLoad)).then((res) => {
            if (res.payload) {
              console.log('payload', res.payload.data.url);
              
              if (res.payload.data.url) {
                window.location.href = res.payload.data.url;
              }
        
              // First, convert the file to Base64 and then store the updated data
              convertToBase64(file).then((base64File) => {
                const newdata = {
                  ...data,
                  transactionId: res.payload.data.transactionId,
                  paymentId: res.payload.data.paymentId,
                  newProfile: base64File, 
                };
        
                console.log('Updated data:', newdata);
        
                // Set data in local storage
                localStorage.setItem('paymentData', JSON.stringify(newdata));
                setDatas(newdata); // Update the state with new data
              }).catch((error) => {
                console.error('Error converting file to Base64:', error);
              });
            }
          });
    }
  };
  
  

    const handleClose = () => {
        onsubmit()
        setShowModal(false); 
      };

    return (
        <>
        <form className="read-only-form">
                <fieldset className="fieldset-section">
                    <legend style={{color: EColor.BROWN}}>Personal Information</legend>

                    <div className="form-group">
                        <label>Candidate's Full Name:</label>
                        <input type="text" value={"Satish Naresh Patil"} readOnly />
                    </div>
                    {/* <div className="form-group">
                        <label>Middle Name:</label>
                        <input type="text" value={'Naresh'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <input type="text" value={'Patil'} readOnly />
                    </div> */}
                    <div className="form-group">
                        <label>Guardian's Full Name:</label>
                        <input type="text" value={'Naresh Maruti Patil' || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Address:</label>
                        <textarea value={'A/406, Galaxy Apt , Vimal dairy lane, 401106' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label> Mobile Number 1:</label>
                        <input type="text" value={'9875645454' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label> Mobile Number 2:</label>
                        <input type="text" value={'9875644545' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Date of Birth:</label>
                        <input type="text" value={'20/10/1997' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Birth Time:</label>
                        <input type="text" value={'02:00 am' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Education:</label>
                        <input type="text" value={"B.E." || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Occupation:</label>
                        <input type="text" value={'Information Technology' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Occupation City:</label>
                        <input type="text" value={'Pune' || ''} readOnly />
                    </div>
                     <div className="form-group">
                        <label>Annual Income:</label>
                        <input type="text" value={`12 Lakhs` || ''} readOnly />
                    </div>
                     <div className="form-group">
                        <label>Sub Cast:</label>
                        <input type="text" value={`Chaturth` || ''} readOnly />
                    </div>
                     <div className="form-group">
                        <label>Height:</label>
                        <input type="text" value={`5ft 8inch` || ''} readOnly />
                    </div>
                     <div className="form-group">
                        <label>Body Color:</label>
                        <input type="text" value={`Fair` || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Expectation:</label>
                        <input type="text" value={'Non-drinker' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Agricultural Land:</label>
                        <input type="text" value={'3' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Gender:</label>
                    </div>
                        <input type="text" value={'Male' || ''} readOnly />
                    <div className="form-group">
                        <label>Birth Place:</label>
                        <input type="text" value={'Mumbai' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Blood Group:</label>
                        <input type="text" value={'O+' || ''} readOnly />
                    </div>
                    {/* <div className="form-group">
        <label>Relation With Guardian:</label>
        <input type="text" value={data?.relWithGuardian || ''} readOnly />
    </div> */}
                    <div className="form-group">
                        <label>Marital Status:</label>
                        <input type="text" value={'Married' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Mother Tongue:</label>
                        <input type="text" value={'Marathi' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Employed In:</label>
                        <input type="text" value={'Private' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Organization:</label>
                        <input type="text" value={'Utkarsh ltd.' || ''} readOnly />
                    </div>
                </fieldset>

                <fieldset className="fieldset-section">
                <legend style={{color: EColor.BROWN}}>Family Details</legend>
                    <div className="form-group">
                        <label>Releation with Guardian:</label>
                        <input type="text" value={'Father' || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Guardian's Occupation:</label>
                        <input type="text" value={'Accountant' || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Family Members:</label>
                        <input type="text" value={'5' || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Number of Brothers:</label>
                        <input type="text" value={'3' || '0'} readOnly />
                    </div>
                    <div className="form-group">
                        <label>Number of Sisters:</label>
                        <input type="text" value={'4' || '0'} readOnly />
                    </div>
                </fieldset>

                <fieldset className="fieldset-section">
                    <legend style={{color: EColor.BROWN}}>Address Details</legend>
                    
                    <div className="form-group">
                        <label>Locality:</label>
                        <input type="text" value={'Kashinagar' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>City:</label>
                        <input type="text" value={'Bhayander' || ''} readOnly />
                    </div>
                    <div className="form-group">
                        <label>State:</label>
                        <input type="text" value={'Maharshtra' || ''} readOnly />
                    </div>
                    
                    <div className="form-group">
                        <label>Country:</label>
                        <input type="text" value={'India' || ''} readOnly />
                    </div>
                </fieldset>

                <h2>Profile Pic</h2>
                <div className="image-upload">
                    <img src={spalsh} alt="Selected" className="uploaded-img" />
                </div>
            </form></>
    );
};

export default SampleForm;
