import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import axios from "axios";

import { EEdu, IEDUApiResponse, IEduState } from "./Edu.models";
import { APIKEYS } from "../../../utils/Keys";



const userInitialState: IEduState = {
    UserLoading: false,
    UserSuccess: null,
    UserError: '',
};


export const getEducData = createAsyncThunk(EEdu.GET_EDUCATION, async () => {
    try {
        const tokanData = {
           username:"jainmatrimonyapi",
           password:"jainmatrimonyapi"
        }
        const Tokan = await axios.post(`${APIKEYS.API}verify`, tokanData)
        //console.log('Tokan-----',Tokan)
        const token =Tokan.data.token;
        if (token) {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };

            const response = await axios.get<IEDUApiResponse>(`${APIKEYS.API}staticeducations`, {headers: headers});
            //console.log('resss',response.data.data)
            if(response) {
                return response.data;
            }
        }
    } catch (e) {
        console.error('Error fetching custome:', e);
        throw e;
    }
});

const getEducDataSlice = createSlice({
    name: EEdu.GET_EDUCATION,
    initialState: userInitialState,
    reducers: {
        resetData: (state) => {
            state.UserSuccess = null;
            state.UserError = null;
            state.UserLoading = false;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(getEducData.pending, (state) => {
                state.UserLoading = true;
            })
            .addCase(getEducData.fulfilled, (state, action) => {
                state.UserLoading = false;
                if (action.payload) {
                    state.UserSuccess = action.payload;
                }
                state.UserError = null;
            })
           .addCase(getEducData.rejected, (state) =>{
            state.UserError = 'something went wrong';
            state.UserLoading = false;
           });
    },
});

export const { resetData } = getEducDataSlice.actions;
export default getEducDataSlice.reducer;