import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import { EPhonePay, INum, IPhone, IPhonepayApiResponse } from "./PhonePay.models";

const userInitialState: IPhone = {
    PhonepayLoading: false,
    PhonepaySuccess: null,
    PhonepayError: '',
};

export const initiatePhonePay = createAsyncThunk(EPhonePay.PHONEPAY, async (data: INum) => {
    try {
        const tokenData = {
            username: "jainmatrimonyapi",
            password: "jainmatrimonyapi"
        };
        
        // Fetch token from API
        const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
        const token = tokenResponse.data.token;
        //console.log('Token:', token);

        if (token) {
            const headers = {
                Authorization: `Bearer ${token}`,
                'Content-Type': 'application/json',
            };
            
            //console.log('API URL:', `${APIKEYS.API}phonepe-payment/${data.amount}/${data.number}`);

            // Make phone payment request
            const response = await axios.post<IPhonepayApiResponse>(`${APIKEYS.API}phonepe-payment/${data.amount}/${data.number}`, null, { headers });

            if (response && response.data && response.data) {
                  //console.log('mmmm',response.data.data)
                // const merchantTransactionId = response.data.data.merchantTransactionId;

                // Store the merchantTransactionId in local storage
                // localStorage.setItem('merchantTransactionId', merchantTransactionId);

                // console.log('Merchant Transaction ID:', merchantTransactionId);
                return response.data;
            }
        }
    } catch (e) {
        console.error('Error fetching customer:', e);
        throw e;
    }
});

const initiatePhonePaySlice = createSlice({
    name: EPhonePay.PHONEPAY,
    initialState: userInitialState,
    reducers: {
        resetData: (state) => {
            state.PhonepaySuccess = null;
            state.PhonepayError = null;
            state.PhonepayLoading = false;
        },
    },

    extraReducers: (builder) => {
        builder
            .addCase(initiatePhonePay.pending, (state) => {
                state.PhonepayLoading = true;
            })
            .addCase(initiatePhonePay.fulfilled, (state, action) => {
                state.PhonepayLoading = false;
                if (action.payload) {
                    state.PhonepaySuccess = action.payload;
                }
                state.PhonepayError = null;
            })
            .addCase(initiatePhonePay.rejected, (state) => {
                state.PhonepayError = 'Something went wrong';
                state.PhonepayLoading = false;
            });
    },
});

export const { resetData } = initiatePhonePaySlice.actions;
export default initiatePhonePaySlice.reducer;
