/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-return */
/* eslint-disable no-else-return */
/* eslint-disable @typescript-eslint/semi */
/* eslint-disable @typescript-eslint/typedef */
/* eslint-disable @typescript-eslint/naming-convention */
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { EIsregister, IMobileRegisterApiResponse, IRegisterMobileState } from "./checkRegister.models";
import axios from "axios";
import { APIKEYS } from "../../../utils/Keys";
import { toast } from "react-toastify";



const userInitialState: IRegisterMobileState = {
  isregisterLoading: false,
  isregisterSuccess: null,
  isregisterError: '',
};

export const isCheckMobile = createAsyncThunk(EIsregister.ISREGISTER, async (data: string) => {
  //console.log('mobile no---',data);
  try {
    const tokanData = {
      username:"jainmatrimonyapi",
      password:"jainmatrimonyapi"
   }
   const Tokan = await axios.post(`${APIKEYS.API}verify`, tokanData)
   const token =Tokan.data.token;
    //console.log(token);
    if (token) {
      const headers = {
        "Authorization": `Bearer ${token}`,
        'content-type': 'application/json',
      };
      //console.log('vvgg',`${APIKEYS.API}customerbymobile/${data}`)
    const response = await axios.get<IMobileRegisterApiResponse>(`${APIKEYS.API}customerbymobile/${data}`, {headers: headers});
    //console.log('respose---',response,response.data.data.custId)
    if (response && response.data && response.data.data.custId ) {
      //console.log('Mobile number is already register with us')
      toast.error('Mobile number is already register with us please try another Number', {
        position:'top-right'

      });
      return response.data;
    }else{
      toast.success('Mobile number is verified successfully', {
        position:'top-right'

      });
    }
    return response.data;
    }
  } catch (error) {
    console.log(error);
    toast.error('Mobile number is already register with us please try another Number', {
      position:'top-right'

    });
    throw error;
  }
});

const isMobileRegisterSlice = createSlice({
  name: EIsregister.ISREGISTER,
  initialState: userInitialState,
  reducers: {
    resetdata: (state) => {
      state.isregisterSuccess = null;
      state.isregisterError = null;
      state.isregisterLoading = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(isCheckMobile.pending, (state) => {
        state.isregisterLoading = true;
      })
      .addCase(isCheckMobile.fulfilled, (state, action) => {
        state.isregisterLoading = false;
        if (action.payload) {
          state.isregisterSuccess = action.payload;
        }
        state.isregisterError = null;
      })
      .addCase(isCheckMobile.rejected, (state, action) => {
        state.isregisterError = 'Something Went Wrong';
        state.isregisterLoading = false;
      });
  },
});

export const { resetdata } = isMobileRegisterSlice.actions;
export default isMobileRegisterSlice.reducer;