import React, { useEffect, useState } from 'react';
import './QrScanner.css'; 
import { useAppDispatch, useAppSelector } from '../../../states/store';
import { initiatePhonePay } from '../../../states/Slice/phonePay/Phonepay.slice';
import { postCustomerRegistration } from '../../../states/Slice/registerUser/register.slice';
import { APIKEYS } from '../../../utils/Keys';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

// A simple loader component
const Loader = () => <div className="spinner"></div>;

const base64ToBlob = (base64) => {
  const byteString = atob(base64.split(',')[1]); // Decode Base64 string
  const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]; // Extract MIME type

  const byteNumbers = new Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    byteNumbers[i] = byteString.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeString });
};

const QRCodePage = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const reducer = useAppSelector((state) => state.registerSlice);
  const [state, setState] = useState('loading');
  const [amount, setAmount] = useState('');


  useEffect(() => {
    if (reducer.registerSuccess?.data) {
      //console.log('payemnt successs -------',reducer.registerSuccess?.data)
      setState('success');
    } else if (reducer.registerError) {
      //console.log('payemnt successs -------',reducer.registerError)
      setState('failed');
    }
  }, [reducer]);


  useEffect(() => {
    if (state !== 'success' && state !== 'failed') {
      const checkAndDispatchPaymentData = () => {
        const paymentData = JSON.parse(sessionStorage.getItem('paymentData') || '{}');
  
        if (paymentData.transactionId && paymentData.paymentId) {
          dispatch(postCustomerRegistration(paymentData)).then((res) => {
            sessionStorage.removeItem('paymentData');
       // Clear interval after dispatch if successful or failed
            if (state === 'success' || state === 'failed') {
              clearInterval(intervalId);
            }
          });
        }
      };
  
      const intervalId = setInterval(() => {
        checkAndDispatchPaymentData();
      }, 5000); // 5-second interval

      const timeout = setTimeout(() => {
        clearInterval(intervalId);
        setState('timeout');
        reload();
      }, 100000)
  
      return () => clearInterval(intervalId);
    }
  }, [state, dispatch]);
  
  const reload = async () => {
    navigate('/');
  }

  const downloadPDF = async () => {
    try {
      const tokenData = {
        username: "jainmatrimonyapi",
        password: "jainmatrimonyapi",
      };
    
      const tokenResponse = await axios.post(`${APIKEYS.API}verify`, tokenData);
      const token = tokenResponse.data.token;
      const response = await fetch(`${APIKEYS.API}download-receipt/${reducer.registerSuccess?.data.custId}`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/pdf',
        },
      });

      if (!response.ok) {
        throw new Error('Failed to download PDF');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'document.pdf');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
      window.URL.revokeObjectURL(url);
      navigate('/')

    } catch (error) {
      console.error('Error downloading PDF:', error);
    }
  };

  return (
    <div className="checkout-container">
      <div className="payment-section">
        <h1>Checkout</h1>
        <div className="amount-details">
          <h2>Payment Details</h2>
          <div className="amount" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <span style={{ color: "black" }}>Total Amount:</span>
            <span className="price">1200 rs</span>
          </div>
        </div>
        <div style={{ display: "flex", flexDirection: "column", rowGap: 12 }}>
          <button 
            style={{ backgroundColor: state === 'success' ? "green" : state === 'failed' ? "red" : 'green' ,display:'flex',flexDirection:"row",justifyContent:"center"}} 
            className="pay-button"
            disabled={state === 'loading'}
            onClick={reload}
          >
            {state === 'loading' ? <Loader /> : `Payment ${state}`}
            {state === 'loading' ? <span > Checking Payment Status.</span> : ``}
          </button>
          {state === 'success' && <button onClick={downloadPDF} className="pay-button">Download Payment receipt</button>}

          {state === 'timeout' && <span style={{ color: "red" }}>We are unable to fetch Payment details at this moment, 
            We will let you know status in some time.
          </span> }
        </div>
      </div>
    </div>
  );
};

export default QRCodePage;
